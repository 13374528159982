<template>
  <div id="patient-card">
    <div class="avatar-content">
      <Avatar :src="patient?.picture" size="90px" />
      <h2 class="patient-name">{{ patient.name }}</h2>
      <small class="medical-record">
        Nº do pronturário:
        <span class="number">{{ patient.medical_record_number }}</span>
      </small>

      <div class="phone-container">
        <b-button
          class="whatsapp-button"
          @click="openWhatsApp(patient.cellphone)"
        >
          <WhatsappIcon class="whatsapp-icon ml-2" />
          Entrar em contato
        </b-button>
      </div>
    </div>

    <hr />

    <div class="profile-content mt-3">
      <div class="content mb-3">
        <h3>Perfil</h3>
        <b-button
          :disabled="!patient.eyecare_bi_id"
          variant="link"
          class="edit-button"
          @click="goPatient(patient?.eyecare_bi_id)"
        >
          Ver perfil completo
        </b-button>
      </div>

      <div v-for="(data, key) in contentData" :key="key" class="content">
        <span class="default-label">{{ data.label }}</span>
        <span class="default-data" :class="data?.class">{{ data.data }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { openWhatsApp } from '@/utils/phoneHelper.js'

export default {
  name: 'PatientCard',
  components: {
    Avatar: () => import('@/components/General/Avatar'),
    WhatsappIcon: () => import('@/assets/icons/whatsapp-icon.svg')
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    clinicId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    patient: {}
  }),
  computed: {
    contentData() {
      return [
        {
          label: 'Idade',
          data: this.patient?.birthday
            ? this.moment().diff(this.patient?.birthday, 'years')
            : '-'
        },
        {
          label: 'Celular',
          data: this.patient?.cellphone
        },
        {
          label: 'Data de nascimento',
          data: this.patient?.birthday
            ? this.moment(this.patient?.birthday).format('DD/MM/YYYY')
            : ''
        },
        {
          label: 'Sexo',
          data: this.patient?.gender,
          class: 'text-capitalize'
        }
      ]
    }
  },
  async created() {
    await this.getPatient()
  },
  methods: {
    openWhatsApp,
    goPatient(id) {
      const route = this.$router.resolve(`/pacientes/${id}`)
      window.open(route.href, '_blank')
    },
    async getPatient() {
      try {
        const { data } = await this.api.glaucoPatientDashboardData(
          this.patientId,
          this.clinicId
        )
        this.patient = data
        this.$emit('update-patient-name', this.patient)
      } catch (error) {
        this.$toast.error('Erro ao buscar paciente')
      }
    }
  }
}
</script>

<style lang="scss">
#patient-card {
  h2,
  h3 {
    font-family: 'Red Hat Display';
  }

  background-color: var(--neutral-000);
  border-radius: 8px;
  padding: 10px 15px;

  .avatar-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .patient-name {
      margin-top: 5%;
      font-size: min(2.6vw, 24px);
      color: var(--dark-blue);
      font-weight: 700;
    }

    .medical-record {
      font-weight: 600;
      font-size: min(1vw, 12px);
      color: var(--type-placeholder);

      .number {
        color: var(--type-active);
      }
    }

    .phone-container {
      margin-top: 5%;
      display: flex;
      align-items: center;
      justify-content: center;

      .whatsapp-button {
        font-weight: 600;
        color: var(--states-success-dark-green);
        background-color: var(--states-success-soft-green);
        border: 1px solid var(--states-success-soft-green);
        border-radius: 8px;
      }
    }
  }

  .profile-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 2%;

      h3 {
        font-size: min(2.6vw, 18px);
        color: var(--dark-blue);
        font-weight: 700;
      }

      .edit-button {
        font-weight: 600;
        font-size: min(1vw, 14px);
      }

      .default-label {
        font-weight: 600;
        font-size: min(1.2vw, 16px);
        color: var(--type-placeholder);
      }

      .default-data {
        font-weight: 600;
        font-size: min(1.2vw, 16px);
        color: var(--type-active);
      }
    }
  }
}
</style>
